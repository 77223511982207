/*
<!-- This script is based on the javascript code of Roman Feldblum (web.developer@programmer.net) -->
<!-- Original script : http://javascript.internet.com/forms/format-phone-number.html -->
<!-- Original script is revised by Eralper Yilmaz (http://www.eralper.com) -->
<!-- Revised script : http://www.kodyaz.com -->
<!-- Format : "(123) 456-7890" -->
*/
var zChar = new Array(' ', '(', ')', '-', '.');
var maxphonelength = 14;
var phonevalue1;
var phonevalue2;
var cursorposition;

function ParseForNumber1(object) {
    phonevalue1 = ParseChar(object.value, zChar);
}
function ParseForNumber2(object) {
    phonevalue2 = ParseChar(object.value, zChar);
}

function backspacerUP(object, e) {
    if (e) {
        e = e;
    } else {
        e = window.event;
    }
    if (e.which) {
        var keycode = e.which;
    } else {
        var keycode = e.keyCode;
    }

    ParseForNumber1(object);

    if (keycode >= 48) {
        ValidatePhone(object);
    }
}

function backspacerDOWN(object, e) {
    if (e) {
        e = e;
    } else {
        e = window.event;
    }
    if (e.which) {
        var keycode = e.which;
    } else {
        var keycode = e.keyCode;
    }
    ParseForNumber2(object);
}

function GetCursorPosition() {
    var t1 = phonevalue1;
    var t2 = phonevalue2;
    var bool = false;
    for (i = 0; i < t1.length; i++) {
        if (t1.substring(i, 1) != t2.substring(i, 1)) {
            if (!bool) {
                cursorposition = i;
                window.status = cursorposition;
                bool = true;
            }
        }
    }
}

function ValidatePhone(object) {
    var p = phonevalue1;

    p = p.replace(/[^\d]*/gi, '');

    if (p.length < 3) {
        object.value = p;
    } else if (p.length == 3) {
        var pp = p;
        var d4 = p.indexOf('(');
        var d5 = p.indexOf(')');
        if (d4 == -1) {
            pp = '(' + pp;
        }
        if (d5 == -1) {
            pp = pp + ')';
        }
        object.value = pp;
    } else if (p.length > 3 && p.length < 7) {
        p = '(' + p;
        var l30 = p.length;
        var p30 = p.substring(0, 4);
        var p30 = p30 + ') '; // add space

        var p31 = p.substring(4, l30);
        pp = p30 + p31;

        object.value = pp;
    } else if (p.length >= 7) {
        p = '(' + p;
        var l30 = p.length;
        var p30 = p.substring(0, 4);
        p30 = p30 + ') '; // add space

        var p31 = p.substring(4, l30);
        pp = p30 + p31;

        var l40 = pp.length;
        var p40 = pp.substring(0, 9); //8
        p40 = p40 + '-';

        var p41 = pp.substring(9, l40); //8
        var ppp = p40 + p41;

        object.value = ppp.substring(0, maxphonelength);
    }

    GetCursorPosition();

    if (cursorposition >= 0) {
        if (cursorposition == 0) {
            cursorposition = 2;
        } else if (cursorposition <= 2) {
            cursorposition = cursorposition + 1;
        } else if (cursorposition <= 4) {
            cursorposition = cursorposition + 3; //2
        } else if (cursorposition == 5) {
            cursorposition = cursorposition + 3; //2
        } else if (cursorposition == 6) {
            //new
            cursorposition = cursorposition + 3; //2
        } else if (cursorposition == 7) {
            //6
            cursorposition = cursorposition + 4; //2
        } else if (cursorposition == 8) {
            //7
            cursorposition = cursorposition + 4;
            e1 = object.value.indexOf(')');
            e2 = object.value.indexOf('-');
            if (e1 > -1 && e2 > -1) {
                if (e2 - e1 == 4) {
                    cursorposition = cursorposition - 1;
                }
            }
        } else if (cursorposition == 9) {
            //7
            cursorposition = cursorposition + 4;
        } else if (cursorposition < 11) {
            cursorposition = cursorposition + 3;
        } else if (cursorposition == 11) {
            cursorposition = cursorposition + 1;
        } else if (cursorposition == 12) {
            cursorposition = cursorposition + 1;
        } else if (cursorposition >= 13) {
            cursorposition = cursorposition;
        }
    }
}

function ParseChar(sStr, sChar) {
    if (sChar.length == null) {
        zChar = new Array(sChar);
    } else zChar = sChar;

    for (i = 0; i < zChar.length; i++) {
        var sNewStr = '';

        var iStart = 0;
        var iEnd = sStr.indexOf(sChar[i]);

        while (iEnd != -1) {
            sNewStr += sStr.substring(iStart, iEnd);
            iStart = iEnd + 1;
            iEnd = sStr.indexOf(sChar[i], iStart);
        }
        sNewStr += sStr.substring(sStr.lastIndexOf(sChar[i]) + 1, sStr.length);

        sStr = sNewStr;
    }

    return sNewStr;
}

if (!String.prototype.startsWith) {
    Object.defineProperty(String.prototype, 'startsWith', {
        value: function (search, rawPos) {
            var pos = rawPos > 0 ? rawPos | 0 : 0;
            return this.substring(pos, pos + search.length) === search;
        },
    });
}

$(document).ready(function () {
    $('body').on('keydown', '.phone-format', function (e) {
        backspacerDOWN(this, e);
    });

    $('body').on('keyup', '.phone-format', function (e) {
        backspacerUP(this, e);
    });

    $('.phone-format').each(function (idx, elem) {
        var phone_number = $(elem).val();

        if (phone_number === '') {
            return;
        }

        if (phone_number.startsWith('+')) {
            phone_number = phone_number.replace(/\+\d(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        }

        $(elem).val(phone_number);
    });
});
